<template>
  <div class="h-full">
    <div
      v-if="loading"
      class="
        flex
        items-center
        left-0
        right-0
        top-0
        bottom-0
        bg-gray-200
        rounded-lg
        text-sm
        min-h-full
      "
    >
      <div class="flex-1 text-center">Please Wait ....</div>
    </div>
    <div v-if="error" class="p-8 my-6 text-sm text-center">
      <p class="my-4">An Error Occured. Please try again</p>
      <trac-button data-test="retry" @click.native="retry()" class="smallest">Retry</trac-button>
    </div>
    <div v-else>
      <div class="flex">
        <div
          :data-test="(tab.label || '').toLowerCase()"
          @click="selectTab(index)"
          class="flex-1 cursor-pointer text-sm text-center py-2"
          :class="{
            'tab-selected': tab.enabled,
            'tab-unselected': !tab.enabled,
          }"
          v-for="(tab, index) in tabs"
          :key="index"
        >
          {{ tab.label }}
        </div>
      </div>
      <div v-if="!emptyTransactionList" class="py-4 text-sm">
        <!-- Content for {{ getCurrentIndex }}: -->
        <!-- {{ getTransactionsForCurrentTab }} -->
        <div class="flex">
          <div class="flex-1">
            <h6 class="text-xs opacity-50">
              {{ getCurrentTab.label }}'s {{ salesOrTransactions ? 'Sales' : 'Transactions' }} Value
            </h6>
            <h2 v-if="showValues" class="text-lg font-semibold">
              {{ getTotals.totalValue | formatPrice }}
            </h2>
            <h2 v-else class="text-lg font-semibold">
              &#8358; ****.**
            </h2>
          </div>
          <div class="flex-1 text-right">
            <h6 class="text-xs opacity-50">Total {{ salesOrTransactions ? 'Sales' : 'Transactions' }}:</h6>
            <h2 class="text-base">
              {{ getTotals.totalQty }}
            </h2>
          </div>
        </div>

        <div class="py-3">
            <h3 class="text-xs pb-1 my-2 border-b text-gray-600 border-gray-300 font-semibold">
              Most Recent Sales
            </h3>
            <div
              v-for="(sale, index) in getRecordsForSelectedTab"
              :key="index+(salesOrTransactions ? 'A' : 'B')"
              class="flex pb-2 border-b border-gray-300 items-center"
            >
              <div class="flex-1">
                <h2 class="text-sm font-semibold capitalize">
                  {{ bankNameType(sale.payment_method) || "N/A" }}
                </h2>
                <div style="font-size: 0.65rem" class="opacity-50 capitalize">
                  {{ sale.store_name || "N/A" }} |
                  {{ getReadAbleTime(sale.created_at) }}
                </div>
              </div>
              <div class="flex-1 text-right">
                <h2 class="text-sm font-semibold">
                  {{ sale.trxn_amount || "N/A" | formatPrice }}
                </h2>
              </div>
            </div>
        </div>
      </div>
      <div
        v-else
        class="w-full flex justify-center flex-col items-center my-24"
      >
        <img src="@/assets/svg/laptop.svg" class="w-16 h-16" alt="" />
        <p class="text-xs text-accentDark mt-2 items-center">
          Your sales will appear here once you accepting payments
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { DateTime } from 'luxon';
export default {
  props: {
    salesOrTransactions: {
      type: Boolean,
      default: false,
      required: true
    },
    showValues: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      transactionList: [],
      error: false,
      loading: false,
      recordsForSelectedTab: []
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    bankNameType(type) {
      if (type === "bank_transfer") {
        return "Bank Transfer (Dynamic)";
      } else if (type === "fixed_account") {
        return "Bank Transfer (Fixed)";
      } else if (type === "pos") {
        return "POS";
      } else if(type === "own_bank_account") {
        return "Own Bank Account";
      }
      else {
        return type;
      }
    },
    getReadAbleTime(time) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      console.log(`Timezone:`, timezone);
      return DateTime.fromISO(time, { zone: timezone }).toFormat('t');
    },
    selectTab(index = 0) {
      this.resetTabs();
      this.transactionList[index].enabled = true;
    },
    resetTabs() {
      this.transactionList.forEach((tab) => (tab.enabled = false));
    },
    retry() {
      this.loading = true;
      this.error = false;
      this.loadData();
    },
    async loadData() {
      this.loading = true;
      const componentData = await this.$store
        .dispatch("FETCH_TRANSACTIONS")
        .catch((err) => {
          this.error = err;
          this.loading = false;
          console.error(err);
        });

      if (componentData.status && !componentData.error) {
        if (componentData.data.today) {
          this.transactionList.push({
            label: "Today",
            ...componentData.data.today,
            transactions:
              componentData.data.today.transaction.most_recent_transactions,
            sales: "",
            enabled: true,
          });
        }
        if (componentData.data.yesterday) {
          this.transactionList.push({
            label: "Yesterday",
            ...componentData.data.yesterday,
            transactions:
              componentData.data.yesterday.transaction.most_recent_transactions,
            enabled: false,
          });
        }
        this.loading = false;
      } else {
        this.loading = false;
        this.error = true;
      }
    },
  },
  computed: {
    tabs() {
      return this.transactionList.map((item, index) => {
        return { ...item };
      });
    },
    getCurrentIndex() {
      return this.tabs.findIndex((tab) => tab.enabled === true);
    },
    getCurrentTab() {
      return this.tabs[this.getCurrentIndex];
    },
    getTotals(){
      return {
        totalQty: this.salesOrTransactions ? this.getCurrentTab.sale.total_transactions : this.getCurrentTab.transaction.total_transactions,
        totalValue: this.salesOrTransactions ? this.getCurrentTab.sale.transaction_total : this.getCurrentTab.transaction.transaction_total
      }
    },
    getTransactionsForCurrentTab() {
      const currentTab = this.tabs[this.getCurrentIndex];
      return currentTab
        ? currentTab.transaction.most_recent_transactions || []
        : [];
    },
    getSalesForCurrentTab() {
      const currentTab = this.tabs[this.getCurrentIndex];
      return currentTab ? currentTab.sale.most_recent_transactions || [] : [];
    },
    getRecordsForSelectedTab() {
      return this.salesOrTransactions ? this.getSalesForCurrentTab : this.getTransactionsForCurrentTab
    },
    emptyTransactionList() {
      return this.salesOrTransactions ? this.getSalesForCurrentTab.length < 1 : this.getTransactionsForCurrentTab.length < 1
      // return (
      //   this.getTransactionsForCurrentTab.length < 1 &&
      //   this.getSalesForCurrentTab.length < 1
      // );
    },
  },
};
</script>

<style lang="postcss" scoped>
.tab-selected {
  @apply border-b border-blue-600;
}
.tab-unselected {
  @apply border-b border-gray-300;
}
</style>