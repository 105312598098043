<template>
  <div 
  :class="{'border-wrapper': loading}"
  class="bg-white relative flex flex-col min-h-full py-8">
    <div
      v-if="loading"
      class="
        absolute
        flex
        items-center
        left-0
        right-0
        top-0
        bottom-0
        bg-gray-200
        rounded-lg
        text-sm
      "
    >
      <div class="flex-1 text-center">Please Wait ....</div>
    </div>
    <!-- <div class="mb-4 text-sm text-center">
      <p class="my-4">An Error Occured: {{ error }}</p>
      <trac-button @click.native="retry()" class="smallest">Retry</trac-button>

    </div> -->
    <div class="flex-initial mx-8">
      <div v-if="hasFixedAccounts && singleFixedAccount">
        <div class="flex items-center mb-2 gap-x-3">
          <div class="rounded-full bank-thumb-bg" style="padding: 0.17rem">
            <img :src="singleFixedAccount.bank_logo" class="w-8 h-8" alt="" />
          </div>
          <div class="text-xs font-bold flex-1">
            {{ singleFixedAccount.bank_name || "N/A" }}
          </div>
        </div>
        <div class="my-8">
          <h6 class="smallest mb-1">
            Account Name: {{ singleFixedAccount.custom_account_name || "N/A" }}
          </h6>
          <div class="flex mt-2 text-xs account-holder">
            <div class="px-4 py-3 flex-1">
              <span class="smallest">Account Number:</span>
              <span class="font-semibold">{{
                singleFixedAccount.custom_account_number
              }}</span>
            </div>
            <button
              data-test="copy-icon"
              @click="doCopy(singleFixedAccount.custom_account_number)"
              class="flex items-center content-end text-right"
            >
              <span v-if="copied" class="light-pale-blue px-3 self-stretch pt-3">Copied!</span>
              <span v-else class="text-right light-pale-blue self-stretch">
                <img class="h-full w-10" src="@/assets/svg/wallet-copy-clipboard.svg" alt="">
              </span>
            </button>
          </div>
        </div>
      </div>
      <div v-else class="p-2 text-xs text-center">
        <img class="w-8 h-8 mb-2 mx-auto" src="@/assets/svg/icon-home-fixed-account.svg" alt="">
        <p class="text-xs">You do not have a fixed account created</p>
        <router-link to="/fixed-account" class="outline-button my-2 smallest tracking-5 active:bg-purple focus:border-secondaryPurple border-primaryBlue border focus:text-secondaryPurple focus:outline-none">Create Fixed Account</router-link>
      </div>
    </div>
    <div v-show="isAdminAccount" class="flex-1"></div>
    <div v-show="isAdminAccount" class="h-px border-t my-3 border-gray-300"></div>
    <div v-show="isAdminAccount" class="flex-1"></div>
    <div v-if="isAdminAccount" class="flex-initial">
      <div v-if="hasWallet" class="flex-initial mx-8">
        <div class="flex items-center gap-x-3">
          <img class="w-10 h-10" src="@/assets/svg/icon-wallet.svg" alt="">
          <div class="flex-1">
            <h2 class="text-xs">Wallet Balance</h2>
            <h1 class="text-lg font-bold">{{ walletAmount | formatPrice }}</h1>
          </div>
          <div class="flex-initial">
            <router-link data-test="view-wallet" class="text-xs text-blue-900" to="wallet">View Wallet</router-link>
          </div>
        </div>
      </div>
      <div v-else class="text-center">
        <img class="w-8 h-8 mb-2 mx-auto" src="@/assets/svg/icon-wallet.svg" alt="">
        <p class="text-xs">You currently do not have any active wallet</p>
        <router-link class="outline-button my-2 smallest tracking-5 active:bg-purple focus:border-secondaryPurple border-primaryBlue border focus:text-secondaryPurple focus:outline-none" to="/wallet">Create Wallet</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_USER_DATA } from '../../browser-db-config/localStorage';
export default {
  data() {
    return {
      copied: false,
      error: false,
      loading: true,
      wallet: {},
      walletAmount: 0,
      loadedFixedAccounts: [],
      authRole: GET_USER_DATA().role ? GET_USER_DATA().role.toLowerCase() : "",
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    retry() {
      this.loading = true;
      this.error = false;
      this.loadData();
    },
    async loadData() {
      const walletDetails = await this.$store.dispatch("FETCH_WALLET_DETAILS");
      const fixedAccounts =
        (await this.$store.dispatch("GET_FIXED_ACCOUNT")) || null;
      if (walletDetails && walletDetails.status) {
        this.wallet = walletDetails;
        this.walletAmount = (walletDetails.data || {}).amount || 0;
        this.loading = false;
      } else {
        this.error = walletDetails.message;
        this.loading = false;
      }

      this.loadedFixedAccounts =
          fixedAccounts && fixedAccounts.status
            ? (fixedAccounts.data || {}).items || []
            : [];
    },
    doCopy(codeString) {
      this.copied = true;
      this.copyToClipboard(codeString);
      const resetAfterSomeTime = setTimeout(() => {
        this.copied = !this.copied;
      }, 3000);
    },
    copyToClipboard(codeString) {
      const onTheFlyTextArea = document.createElement("textarea");
      onTheFlyTextArea.value = codeString;
      document.body.appendChild(onTheFlyTextArea);
      onTheFlyTextArea.select();

      const copyOperation = document.execCommand("copy");
      if (copyOperation) {
        // console.log("Copied!");
        document.body.removeChild(onTheFlyTextArea);
        return Promise.resolve(true);
      } else {
        // console.warn("copy fail");
        return Promise.reject(new Error("Clipboard Copy Failed"));
      }
    },
  },
  computed: {
    isAdminAccount() {
      return this.authRole === 'admin'
    },
    fixedAccounts() {
      return this.loadedFixedAccounts || [];
    },
    hasFixedAccounts() {
      return this.fixedAccounts.length > 0;
    },
    singleFixedAccount() {
      return this.fixedAccounts[0] || null;
    },
    hasWallet() {
      return (this.wallet && this.wallet.data) || false
    }
  },
};
</script>

<style scoped>
.light-pale-blue {
  background: #E5F6FE;
}
.bank-thumb-bg {
  background: #d6f1fd;
}
.account-holder {
  background: #f6fcff;
  border: 1px dashed #a8a8a8;
  border-radius: 5px;
}
.border-wrapper {
  border: 15px solid white;
}
.outline-button {
  @apply inline-block
              px-3
              font-semibold
              text-primaryBlue
              py-1
              rounded
              leading-6
              tracking-normal
}
</style>