<template>
  <div class="relative">
    <!-- No Data Message -->
    <div v-if="!loading && noData" class="overlay">
        <div class="flex-1 text-center">
          <div class="py-4">
            <img src="@/assets/svg/laptop.svg" class="w-16 h-16 mx-auto" alt="" />
            <p class="text-xs mx-5 text-accentDark mt-2 mb-4 items-center">
              Graph information will show once you have transactions for the last 7 days
            </p>
            <trac-button data-test="retry" @click.native="retry()" class="smallest">Retry</trac-button>
          </div>
        </div>
    </div>
    <!-- No Data Message -->

    <!-- Loading -->
    <div v-if="loading" class="overlay">
      <div class="flex-1 text-center">
        Please Wait ....
      </div>
    </div>
    <!-- Loading -->
    <!-- Toggle between Sales and Volumne -->
    <div class="mb-4 flex justify-end items-center gap-x-5">
      <div :class="{ 'font-bold': toggle }" class="flex-initial text-sm">
        Sales
      </div>
      <div data-test="sales-toggle/transaction" @click="toggle = !toggle" class="border-none cursor-pointer">
        <img v-if="toggle" src="@/assets/svg/toggle-left.svg" alt="" />
        <img v-else src="@/assets/svg/toggle-right.svg" alt="" />
      </div>
      <div :class="{ 'font-bold': !toggle }" class="flex-initial text-sm">
        Transactions
      </div>
    </div>
    <div style="background: #f6fcff" class="pt-6">
      <!-- Toggle Summary for Sales -->
      <div v-if="toggle" class="mb-4 flex items-center gap-x-2 mx-6">
        <div v-if="showSalesValueGraph" class="flex-initial">
          <h6 class="text-xs">Total Sale Value</h6>
          <h4 v-if="showValues" class="text-md font-semibold">{{totalSales || '0000' | formatPrice}}</h4>
          <h4 v-else class="text-md font-semibold">&#8358; ****.**</h4>
        </div>
        <div v-else class="flex-initial">
          <h6 class="text-xs">Total Number of Sales</h6>
          <h4 class="text-md font-semibold">{{totalSalesVolume || '0'}}</h4>
        </div>
        <div class="flex-1"></div>
        <div :class="{ 'font-bold': valueOrVolume }" class="flex-initial text-xs">
          Volume
        </div>
        <div data-test="volume/value-toggle" @click="valueOrVolume = !valueOrVolume" class="border-none cursor-pointer">
          <img class="w-6" v-if="valueOrVolume" src="@/assets/svg/toggle-left.svg" alt="" />
          <img class="w-6" v-else src="@/assets/svg/toggle-right.svg" alt="" />
        </div>
        <div :class="{ 'font-bold': !valueOrVolume }" class="flex-initial text-xs">
          Value
        </div>
      </div>

      <div v-else class="mb-4 flex items-center gap-x-2 mx-6">
        <div v-if="showTransactionValueGraph" class="flex-initial">
            <h6 class="text-xs">Total Transaction Value</h6>
            <h4 v-if="showValues" class="text-md font-semibold">{{ totalTransactionsVolume || '0000' | formatPrice}}</h4>
            <h4 v-else class="text-md font-semibold">&#8358; ****.**</h4>
        </div>
        <div v-else class="flex-initial">
            <h6 class="text-xs">Total Number of Transactions</h6>
            <h4 class="text-md font-semibold">{{totalTransactions || '0'}}</h4>
        </div>
        <div class="flex-1"></div>
        <div :class="{ 'font-bold': valueOrVolume }" class="flex-initial text-xs">
          Volume
        </div>
        <div @click="valueOrVolume = !valueOrVolume" class="border-none cursor-pointer">
          <img class="w-6" v-if="valueOrVolume" src="@/assets/svg/toggle-left.svg" alt="" />
          <img class="w-6" v-else src="@/assets/svg/toggle-right.svg" alt="" />
        </div>
        <div :class="{ 'font-bold': !valueOrVolume }" class="flex-initial text-xs">
          Value
        </div>
      </div>

      <div class="border-b border-gray-400"></div>

      <zingchart
        v-if="showSalesValueGraph"
        id="zingchart-vue-99-graph-id0-scale_x-item_title"
        :height="280"
        :data="salesValueGraph || {}"
      ></zingchart>

      <zingchart
        v-if="showSalesVolumeGraph"
        style="background: #f6fcff"
        id="zingchart-vue-99-graph-id1-scale_x-item_title"
        :height="280"
        :data="salesVolumeGraph || {}"
      ></zingchart>

      <zingchart
        v-if="showTransactionValueGraph"
        style="background: #f6fcff"
        id="zingchart-vue-99-graph-id1-scale_x-item_title"
        :height="280"
        :data="transactionValueGraph|| {}"
      ></zingchart>

      <zingchart
        v-if="showTransactionVolumeGraph"
        style="background: #f6fcff"
        id="zingchart-vue-99-graph-id1-scale_x-item_title"
        :height="280"
        :data="transactionsGraph || {}"
      ></zingchart>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  async created() {
    await this.loadData()
  },
  props: {
    showValues: {
      type: Boolean,
      default: true 
    },
  },
  data() {
    return {
      toggle: false,
      valueOrVolume: false,
      salesValueOrSalesVolume: false,
      graphData: {},
      loading: true
    };
  },
  watch: {
    toggle(newValue, oldValue) {
      if (!(newValue === oldValue)) {
        this.$emit('toggleUpdate', newValue)
      }
    }
  },
  computed: {
    dateLabels() {
        const dateOfDays = Object.keys(this.graphData).length > 0 ? Object.keys(this.graphData) : []
        return dateOfDays.sort().map((dateString) => {
            const numberOfDay = moment(dateString).date()
            return moment().localeData().ordinal(numberOfDay)
        })
    },
    noData() {
      return Object.keys(this.graphData).length < 1
    },
    showSalesValueGraph() {
      return this.toggle && this.valueOrVolume === false;
    },
    showSalesVolumeGraph() {
      return this.toggle && this.valueOrVolume === true;
    },
    showTransactionValueGraph() {
      return this.toggle === false && this.valueOrVolume === false;
    },
    showTransactionVolumeGraph() {
      return this.toggle === false && this.valueOrVolume === true;
    },
    transactionData() {
      return {
        trasnactions: Object.keys(this.graphData).map(item => this.graphData[item].totalTransactions),
        value: Object.keys(this.graphData).map(item => this.graphData[item].totalValue),
        totalSales: Object.keys(this.graphData).map(item => this.graphData[item].totalSales),
        totalSalesValue: Object.keys(this.graphData).map(item => this.graphData[item].totalSalesValue)
      }
    },
    totalSales() {
      return this.transactionData.totalSalesValue.reduce((previousValue, nextValue) => previousValue+nextValue, 0)
    },
    totalSalesVolume() {
      return this.transactionData.totalSales.reduce((previousValue, nextValue) => previousValue+nextValue, 0)
    },
    totalTransactions() {
      return this.transactionData.trasnactions.reduce((previousValue, nextValue) => previousValue+nextValue, 0)
    },
    totalTransactionsVolume() {
      return this.transactionData.value.reduce((previousValue, nextValue) => previousValue+nextValue, 0)
    },
    salesValueGraph() {
      return this.returnGraphObjectData(this.transactionData.totalSalesValue);
    },
    salesVolumeGraph() {
      return this.returnGraphObjectData(this.transactionData.totalSales)
    },
    transactionsGraph(){
      return this.returnGraphObjectData(this.transactionData.trasnactions)
    },
    transactionValueGraph() {
      return this.returnGraphObjectData(this.transactionData.value)
    }
  },
  methods: {
    returnGraphObjectData(arrayOfValues = []) {
      return {
        type: "area",
        backgroundColor: "none",
        scaleY: {
          lineColor: "none",
          tick: {
            visible: false,
          },
          guide: {
            visible: false,
          },
          item: {
            visible: false,
          },
        },
        scaleX: {
          label: {
            /* Add a scale title with a label object. */
            text: ''
          },
          /* Add your scale labels with a labels array. */
          labels: this.dateLabels,
        },
        series: [
          {
            values: arrayOfValues,
            "background-color": "#07b2c9 #234398",
            "alpha-area": 0.2,
            "line-color": "#253B95",
            marker: {
              "background-color": "#ffffff" /* hexadecimal or RGB value */,
              size: 4 /* in pixels */,
              "border-color": "#253b95" /* hexadecimal or RBG value */,
              "border-width": 2 /* in pixels */,
            },
          },
        ],
      };
    },
    async loadData() {
      const graphData = await this.$store
        .dispatch("GRAPH")
        .catch((err) => {
          console.error("Error Loading Graph: ", err);
        });

      if (graphData.status) {
        this.graphData = graphData.data;
        this.loading = false
      } else {
        console.error("Error Loading Graph: ", graphData.message);
      }
    },
    retry() {
      this.loading = true
      this.loadData()
    }
  },
};
</script>

<style lang="postcss" scoped>
.overlay {
  @apply flex items-center absolute z-10 left-0 right-0 top-0 bottom-0 bg-gray-200 rounded-lg text-sm min-h-full
}
.blue-text {
  color: #253b95;
}
.blue-bg {
  background: #253b95;
}
</style>